<template>
	<div>
		<scNav navTag="scZcfg" :themeColor="themeColor"></scNav>
		<div class="sc-width">
			<probm :probm="probm"></probm>
		</div>
		<bottom :bgColor="themeColor"></bottom>
	</div>
</template>

<script>
	import scNav from '@/components/special/scNav'
	import probm from "@/components/probm/probm"
	export default {
		data() {
			return {
				themeColor:"",
				probm:{},
				themeColor:""
			}
		},
		components:{
			probm,
			scNav
		},
		created() {
			let type = this.$route.query.type
			if(type==1) {
				// 专精特新版
				this.themeColor = "#386eb8"
			}else if(type==2) {
				// 科技创新版	
				this.themeColor = "#47a8c8"
			}else if(type==3) {
				// 交易版
				this.themeColor = "#d3a21e"
			}
			let id = this.$route.query.id
			this.getZcDail(type,id)
		},
		methods:{
			// 查询政策详情
			getZcDail(type,id) {
				let url = ""
				if(type==1) {
					url = this.$api.base+"Specialization/getDetail"
				}else if(type==2) {
					url = this.$api.base+"Technology/getDetail"
				}else if(type==3) {
					url = this.$api.base+"Trading/getDetail"
				}
				let params = {
					id
				}
				this.$axios.get(
					url,{params}
				).then(res=>{
					if(res.code) {
						this.probm = res.data
					}
				})
			}
		}
	}
</script>

<style>
</style>